
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import TextField from '@/components/processing_steps/commons/fields/TextField.vue'
import { IHdsFilter, IHdsFilterSet, IHdsFilterValueBadge } from '@/types/hdsFilter'
import CharField from '../processing_steps/commons/fields/CharField.vue'
import SingleSelectField from '../processing_steps/commons/fields/SingleSelectField.vue'
import MultiSelectField from '../processing_steps/commons/fields/MultiSelectField.vue'
import FilterValueBadge from './FilterValueBadge.vue'

@Component({
  components: {
    CharField,
    TextField,
    MultiSelectField,
    SingleSelectField,
    FilterValueBadge
  }
})
export default class FilterContainer extends Vue {
  @Prop({ required: true }) filterSet!: IHdsFilterSet

  categories = [
    {
      key: "organization",
      title: 'Organisation - Wer steht dahinter?',
      open: false,
      subCategories: [
        {
          key: "organization_name",
          title: '',
          filters: [],
          open: false
        },
        {
          key: "funding_scope_slug",
          title: '',
          filters: [],
          open: false
        },
        {
          key: "organizations_subcategories",
          title: '',
          filters: [],
          open: false
        },
        {
          key: "organizations_number_of_employees",
          title: '',
          filters: [],
          open: false
        },
        {
          key: "organization_legal_form",
          title: '',
          filters: [],
          open: false
        },
        {
          key: "organization_statutory_purpose",
          title: '',
          filters: [],
          open: false
        }
      ]
    },
    {
      key: "content",
      title: 'Inhalt - Wie wird geholfen?',
      open: false,
      subCategories: [
        {
          key: "funding_scope_target_group",
          title: '',
          filters: [],
          open: false
        },
        {
          key: "funding_scope_approach",
          title: '',
          filters: [],
          open: false
        },
        {
          key: "funding_scope_society_goals",
          title: '',
          filters: [],
          open: false
        },
        {
          key: 'funding_scope_education_goals',
          title: '',
          filters: [],
          open: false
        },
        {
          key: 'funding_scope_crisis_relief',
          title: '',
          filters: [],
          open: false
        },
        {
          key: 'funding_scope_elderly',
          title: '',
          filters: [],
          open: false
        },
        {
          key: 'funding_scope_social',
          title: '',
          filters: [],
          open: false
        },
        {
          key: 'funding_scope_sport',
          title: '',
          filters: [],
          open: false
        },
        {
          key: 'funding_scope_animal_welfare',
          title: '',
          filters: [],
          open: false
        },
        {
          key: 'funding_scope_youth_welfare',
          title: '',
          filters: [],
          open: false
        },
        {
          key: 'funding_scope_environment',
          title: '',
          filters: [],
          open: false
        },
        {
          key: 'funding_scope_sdg',
          title: '',
          filters: [],
          open: false
        },
        {
          key: 'funding_scope_science',
          title: '',
          filters: [],
          open: false
        },
        {
          key: 'funding_scope_art',
          title: '',
          filters: [],
          open: false
        }
      ]
    },
    {
      key: "region",
      title: 'Region - Wo wird geholfen?',
      open: false,
      subCategories: [
        {
          key: 'organization_city',
          title: '',
          filters: [],
          open: false
        },
        {
          key: "organization_country",
          title: '',
          filters: [],
          open: false
        }
      ]
    },
    {
      key: "details",
      title: 'Details - Merkmale des Projekts',
      open: false,
      subCategories: [
        {
          key: "project_special_events",
          title: '',
          filters: [],
          open: false
        },
        {
          key: "funding_codes",
          title: '',
          filters: [],
          open: false
        }
      ]
    }
  ]

  get allAppliedValues () {
    return this.filterSet.filters
      .filter((filter: IHdsFilter) => filter.values.length > 0)
      .map((filter: IHdsFilter) => {
        return filter.values.map((value: string | number | boolean) => {
          return {
            text: filter.filterOptions.find(opt => opt.value === value)?.text || '',
            value,
            filterLabel: filter.label,
            isEditable: !filter.readOnly && !this.filterSet.isAdminFilterSet,
            filterQueryParam: filter.query_param
          }
        })
      })
      .flat()
  }

  get categorizedFilters() {
    const categories = this.categories
    const organizationCategory = categories.find(cat => cat.key === 'organization')
    const detailsCategory = categories.find(cat => cat.key === 'details')
    const contentCategory = categories.find(cat => cat.key === 'content')
    const regionCategory = categories.find(cat => cat.key === 'region')

    this.filterSet.filters.forEach(filter => {
      if (filter.query_param === 'organization_slug') {
        const subCategory = organizationCategory?.subCategories.find(sub => sub.key === 'organization_name')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_slug') {
        const subCategory = organizationCategory?.subCategories.find(sub => sub.key === 'funding_scope_slug')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_codes') {
        const subCategory = detailsCategory?.subCategories.find(sub => sub.key === 'funding_codes')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'organizations_subcategories') {
        const subCategory = organizationCategory?.subCategories.find(sub => sub.key === 'organizations_subcategories')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'organizations_number_of_employees') {
        const subCategory = organizationCategory?.subCategories.find(sub => sub.key === 'organizations_number_of_employees')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'organization_legal_form') {
        const subCategory = organizationCategory?.subCategories.find(sub => sub.key === 'organization_legal_form')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'organization_statutory_purpose') {
        const subCategory = organizationCategory?.subCategories.find(sub => sub.key === 'organization_statutory_purpose')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'organization_city') {
        const subCategory = regionCategory?.subCategories.find(sub => sub.key === 'organization_city')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'organization_country') {
        const subCategory = regionCategory?.subCategories.find(sub => sub.key === 'organization_country')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_target_group') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_target_group')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_approach') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_approach')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_society_goals') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_society_goals')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_education_goals') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_education_goals')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_crisis_relief') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_crisis_relief')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_elderly') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_elderly')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_social') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_social')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_sport') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_sport')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_animal_welfare') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_animal_welfare')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_youth_welfare') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_youth_welfare')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_environment') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_environment')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_sdg') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_sdg')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_science') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_science')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'funding_scope_art') {
        const subCategory = contentCategory?.subCategories.find(sub => sub.key === 'funding_scope_art')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      } else if (filter.query_param === 'project_special_events') {
        const subCategory = detailsCategory?.subCategories.find(sub => sub.key === 'project_special_events')
        if (subCategory) {
          subCategory.filters.push(filter)
          subCategory.title = filter.label
        }
      }
    })

    return categories
  }

  mounted () {
    this.updateFilterString()
  }

  @Watch('filterSet')
  onFilterSetChange(newSet) {
    this.filterSet = newSet
  }

  subcategoryIsReadOnly(subCategory: { key: string; filters: IHdsFilter[] }) {
    return subCategory.filters.every((filter: IHdsFilter) => filter.readOnly)
  }

  getFieldComponent(filter: IHdsFilter) {
    switch (filter.widget) {
      case 'varchar':
        return 'VarcharField'
      case 'text':
        return 'TextField'
      case 'date':
        return 'DateField'
      case 'checkbox':
        return 'CheckboxField'
      case 'select':
        return 'SingleSelectField'
      case 'multiselect':
        return 'MultiSelectField'
      default:
        return 'div'
    }
  }

  getComponentProps(filter: IHdsFilter) {
    switch (filter.widget) {
      case 'varchar':
      case 'text':
      case 'date':
      case 'checkbox':
        return {
          value: filter.values[0],
          label: filter.label,
          required: false,
          readOnly: filter.readOnly,
          error: ''
        }
      case 'select':
        return {
          value: filter.values[0],
          options: filter.filterOptions,
          label: filter.label,
          required: false,
          readOnly: filter.readOnly,
          error: ''
        }
      case 'multiselect':
        return {
          value: filter.values,
          options: filter.filterOptions,
          required: false,
          readOnly: filter.readOnly,
          error: '',
          small: true
        }
      default:
        return {}
    }
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  onFilterChange(queryParam: string, value: any) {
    const filter = this.filterSet.filters.find(f => f.query_param === queryParam)
    if (filter) {
      if (Array.isArray(value)) {
        filter.values = value
      } else {
        filter.values = [value]
      }
    }
    this.updateFilterString()
  }

  updateFilterString() {
    const queryString = this.filterSet.filters
      .filter((filter: IHdsFilter) => filter.values.length > 0)
      .map((filter: IHdsFilter) => {
        const values = filter.values.join(',')
        return `${filter.query_param}=${values}`
      })
      .join('&')
    this.$emit('queryStringChanged', queryString)
  }

  toggleSubCategory(categoryIndex: number, subCategoryIndex: number) {
    this.categories[categoryIndex].subCategories.forEach((subCategory, index) => {
      this.$set(subCategory, 'open', index === subCategoryIndex ? !subCategory.open : false)
    })
  }

  toggleCategory(categoryKey: string) {
    this.categories.forEach(cat => {
      if (cat.key !== categoryKey) {
        cat.open = false
      } else {
        cat.open = !cat.open
      }
    })
  }

  removeFilterValue (filterValueBadge: IHdsFilterValueBadge) {
    const filter = this.filterSet.filters.find(f => f.query_param === filterValueBadge.filterQueryParam)
    if (filter) {
      filter.values = filter.values.filter(value => value !== filterValueBadge.value)
    }
    this.updateFilterString()
  }
}
