

import { Mixins, Component, Prop, Ref } from 'vue-property-decorator'
import UserMixin from '@/mixins/UserMixin'
import { IProjectCategory } from '@/types/projects'
import { ISubCategory } from '@/types/foerderApp'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'
import { BDropdown } from 'bootstrap-vue'
import ToastMixin from '@/mixins/ToastMixin'
import FormContainer from './FormContainer.vue'

@Component({
  components: {
    FormContainer
  },
  mixins: [UserMixin]
})
export default class SubCategoryForm extends Mixins(UserMixin, ToastMixin) {
  @Ref('dropdown') readonly dropdown!: BDropdown | null
  @Prop() projectCategories: IProjectCategory[]
  @Prop() instance: { type: 'organization' | 'project' | 'funding_scope', slug: string }
  @Prop({ default: () => [] }) differentProjectCategoryIds: number[]

  subCategories: ISubCategory[] = []
  mandatoryProjectcategories: IProjectCategory[] = []
  optionalProjectCategories: IProjectCategory[] = []
  availableSubCategories: ISubCategory[] = []
  search = ""
  shownCategories: IProjectCategory[] = []

  get filteredSubCategories (): ISubCategory[] {
    return this.availableSubCategories.filter(option => option.title.toLowerCase().includes(this.search.toLowerCase()))
  }

  stepIsValid (): boolean {
    for (const projectCategory of this.mandatoryProjectcategories) {
      let minTags = 0
      if (this.instance.type === "organization") {
        minTags = projectCategory.min_tags_in_organizations
      } else if (this.instance.type === "project") {
        minTags = projectCategory.min_tags_in_projects
      } else if (this.instance.type === "funding_scope") {
        minTags = projectCategory.min_tags_in_funding_scopes
      }
      if (projectCategory.selectedTags.length < minTags) {
        return false
      }
    }
    return true
  }

  async mounted (): Promise<void> {
    await this.setCategoriesAndShownCategories()
  }

  getError (): { title: string, message: string} {
    return {
      title: this.$gettext('Error'),
      message: this.$gettext('You have to choose at least one tag in each mandatory category')
    }
  }

  async setCategoriesAndShownCategories (): Promise<void> {
    await this.setCategories()
    this.setShownCategories()
  }

  async updateTagsAndShownCategories (projectCategory: IProjectCategory): Promise<void> {
    const res = await this.fetchSubCategories(projectCategory)
    const tags = res.availableTags
    const selectedTags = res.selectedTags
    const mandatoryCategory = this.mandatoryProjectcategories.find(category => category.slug === projectCategory.slug)
    if (mandatoryCategory) {
      mandatoryCategory.tags = tags
      mandatoryCategory.selectedTags = selectedTags
      this.mandatoryProjectcategories = [...this.mandatoryProjectcategories]
    }

    const optionalCategory = this.optionalProjectCategories.find(category => category.slug === projectCategory.slug)
    if (optionalCategory) {
      optionalCategory.tags = tags
      optionalCategory.selectedTags = selectedTags
      this.optionalProjectCategories = [...this.optionalProjectCategories]
    }
    this.setShownCategories()
  }

  async setCategories (): Promise<void> {
    const mandatoryProjectcategories = this.projectCategories.filter(projectCategory => this.isMandatory(projectCategory))
    const optionalProjectCategories = this.projectCategories.filter(projectCategory => !this.isMandatory(projectCategory))
    for (const projectCategory of optionalProjectCategories) {
      const res = await this.fetchSubCategories(projectCategory)
      projectCategory.tags = res.availableTags
      projectCategory.selectedTags = res.selectedTags
    }
    for (const projectCategory of mandatoryProjectcategories) {
      const res = await this.fetchSubCategories(projectCategory)
      projectCategory.tags = res.availableTags
      projectCategory.selectedTags = res.selectedTags
    }
    this.mandatoryProjectcategories = mandatoryProjectcategories
    this.optionalProjectCategories = optionalProjectCategories
  }

  setShownCategories (): void {
    this.shownCategories = this.optionalProjectCategories.filter(projectCategory => projectCategory.selectedTags.length > 0)
  }

  getChooseTagsText (projectCategory: IProjectCategory): { title: string, explain: string} {
    if (this.isMandatory(projectCategory)) {
      let minAmount = 0
      if (this.instance.type === "organization") {
        minAmount = projectCategory.min_tags_in_organizations
      } else if (this.instance.type === "project") {
        minAmount = projectCategory.min_tags_in_projects
      } else if (this.instance.type === "funding_scope") {
        minAmount = projectCategory.min_tags_in_funding_scopes
      }
      return {
        title: this.$gettextInterpolate(this.$gettext('Tags %{projectCategoryTitle} *'), { projectCategoryTitle: projectCategory.title }),
        explain: this.$gettextInterpolate(this.$gettext('(choose at least %{minAmount} tags)'), { minAmount })
      }
    }
  }

  // e.g. an orga has to have at least three subcategories in the category "education"
  isMandatory (projectCategory: IProjectCategory): boolean {
    if (this.instance.type === "organization") {
      return projectCategory.min_tags_in_organizations > 0
    } else if (this.instance.type === "project") {
      return projectCategory.min_tags_in_projects > 0
    } else if (this.instance.type === "funding_scope") {
      return projectCategory.min_tags_in_funding_scopes > 0
    }
    return false
  }

  async addTag (slug: string, projectCategory: IProjectCategory): Promise<void> {
    const data = {
      slug,
      project_category: projectCategory.slug,
      instance_type: this.instance.type,
      instance_slug: this.instance.slug
    }
    await axios.post(API_URLS.SUB_CATEGORIES.ADD, data)
      .then(async () => {
        await this.updateTagsAndShownCategories(projectCategory)
      })
  }

  removeTag (subCategory: ISubCategory, projectCategory: IProjectCategory): void {
    this.subCategories = this.subCategories.filter(tag => tag.slug !== subCategory.slug)
    this.availableSubCategories.push(subCategory)
    const data = {
      data: {
        instance_type: this.instance.type,
        instance_slug: this.instance.slug
      }
    }
    axios.delete(API_URLS.SUB_CATEGORIES.REMOVE(subCategory.slug), data).then(async () => {
      await this.updateTagsAndShownCategories(projectCategory)
    })
  }

  async removeAllTagsInCategory (projectCategory: IProjectCategory): Promise<void> {
    for (const subCategory of projectCategory.selectedTags) {
      this.removeTag(subCategory, projectCategory)
    }
    await this.updateTagsAndShownCategories(projectCategory)
  }

  showTagsForCategory (projectCategory: IProjectCategory): void {
    this.shownCategories.push(projectCategory)
  }

  getIsSelected (subCategory: ISubCategory): boolean {
    if (this.instance.type === 'project') {
      return subCategory.projects.includes(this.instance.slug)
    } else if (this.instance.type === 'organization') {
      return subCategory.organizations.includes(this.instance.slug)
    } else if (this.instance.type === 'funding_scope') {
      return subCategory.funding_scopes.includes(this.instance.slug)
    }
  }

  async fetchSubCategories (projectCategory: IProjectCategory): Promise<{ availableTags: ISubCategory[], selectedTags: ISubCategory[] }> {
    let availableTags = []
    let selectedTags = []
    await axios.get(API_URLS.SUB_CATEGORIES.LIST_FOR_PROJECT_CATEGORY(projectCategory.slug))
      .then(response => {
        if (this.instance.type === 'project') {
          availableTags = response.data.results.filter(subCategory => !subCategory.projects.includes(this.instance.slug))
          selectedTags = response.data.results.filter(subCategory => subCategory.projects.includes(this.instance.slug))
        } else if (this.instance.type === 'organization') {
          availableTags = response.data.results.filter(subCategory => !subCategory.organizations.includes(this.instance.slug))
          selectedTags = response.data.results.filter(subCategory => subCategory.organizations.includes(this.instance.slug))
          return {
            availableTags,
            selectedTags
          }
        } else if (this.instance.type === 'funding_scope') {
          availableTags = response.data.results.filter(subCategory => !subCategory.funding_scopes.includes(this.instance.slug))
          selectedTags = response.data.results.filter(subCategory => subCategory.funding_scopes.includes(this.instance.slug))
        }
      })
    return {
      availableTags,
      selectedTags
    }
  }
}
