
import { Component, Vue, Prop } from 'vue-property-decorator'
import CharField from '../fields/CharField.vue'
import IbanField from '../fields/IbanField.vue'
import axios from 'axios'
import { IPSBankAccountForm } from '@/types/processingSteps'
import HdsCheckboxCross from '../fields/HdsCheckboxCross.vue'

@Component({
  components: {
    CharField,
    IbanField,
    HdsCheckboxCross
  }
})
export default class PSBankAccountForm extends Vue {
  @Prop() bankAccountForm!: IPSBankAccountForm
  @Prop() bankAccountFormErrors!: IPSBankAccountForm
  @Prop(({ default: false })) readOnly!: boolean

  async fetchIban (): Promise<void> {
    const iban = this.bankAccountForm.iban.replace(/\s/g, '')
    if (!iban || iban.length < 15) {
      return
    }
    const url = "/api/v4/iban/" + iban + "/"
    await axios.get(url).then((response) => {
      const bic = response.data.bic
      this.bankAccountForm.bic = bic
    }).catch(() => {
      this.$emit("iban-not-found")
    })
  }
}
