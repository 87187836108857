
import { Component, Prop, Ref, Mixins } from 'vue-property-decorator'
import { IHdsFilter, IHdsFilterSet, IHdsFilterType, IHdsWidgetType, SelectTableProject } from '@/types/hdsFilter'
import FilterContainer from '../hds_filter/FilterContainer.vue'
import ConfirmModal from '../processing_steps/commons/modals/ConfirmModal.vue'
import axios from 'axios'
import FilterTable from './FilterTable.vue'
import FilteredProjectTable from './FilteredProjectTable.vue'
import { API_URLS } from '@/utils/helpers'
import FilterOptionsMixin from '@/mixins/FilterOptionsMixin'
import HdsFilterMixin from '@/mixins/HdsFilterMixin'
import SingleSelectField from '@/components/processing_steps/commons/fields/SingleSelectField.vue'
import IntegerField from '@/components/processing_steps/commons/fields/IntegerField.vue'

@Component({
  components: {
    FilterContainer,
    ConfirmModal,
    FilterTable,
    FilteredProjectTable,
    SingleSelectField,
    IntegerField
  }
})
export default class ShowcaseProjectFilter extends Mixins(FilterOptionsMixin, HdsFilterMixin) {
  @Ref('filterTable') filterTable!: FilterTable
  @Prop() showcaseSlug: string
  @Prop() instanceType: 'showcase_page' | 'showcase_template'
  @Prop() isGrowingFilter: boolean
  @Prop() projectLimit: number
  @Prop() isSiteAdmin: boolean

  localIsGrowingFilter = false
  localProjectLimit = 0

  adminFilterSets: IHdsFilterSet[] = []
  filterSets: IHdsFilterSet[] = []
  currentFilterSet: IHdsFilterSet | null = null
  projects: SelectTableProject[] = []
  blacklistedProjects: string[] = []
  filterModalOpen = false
  isEditing = false

  retrieveUrl = ""

  async mounted() {
    this.localIsGrowingFilter = this.isGrowingFilter
    this.localProjectLimit = this.projectLimit
    this.requiredFilterOptions = [
      'tags_mitgliedschaft', 'organization', 'funding_scope',
      'number_of_employees', 'organization_legal_form', 'funding_codes',
      'organization_statutory_purpose', 'organization_city', 'organization_country',
      'funding_scope_target_group', 'funding_scope_approach', 'funding_scope_society_goals',
      'funding_scope_education_goals', 'funding_scope_crisis_relief', 'funding_scope_elderly',
      'funding_scope_social', 'funding_scope_sport', 'funding_scope_animal_welfare',
      'funding_scope_environment', 'funding_scope_youth_welfare', 'funding_scope_sdg',
      'funding_scope_science', 'funding_scope_art', 'project_special_events'
    ]
    await this.fetchFilterOptions()
    await this.fetchFilterSets()
  }

  setFilterOptions(filterSet: IHdsFilterSet) {
    const organizationFilter = filterSet.filters.find(filter => filter.query_param === 'organization_slug')
    if (organizationFilter) {
      organizationFilter.filterOptions = this.organizationOptions
    }
    const fundingScopeFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_slug')
    if (fundingScopeFilter) {
      fundingScopeFilter.filterOptions = this.fundingScopeOptions
    }
    const memberShipFilter = filterSet.filters.find(filter => filter.query_param === 'organizations_subcategories')
    if (memberShipFilter) {
      memberShipFilter.filterOptions = this.membershipOptions
    }
    const numberOfEmployeesFilter = filterSet.filters.find(filter => filter.query_param === 'organizations_number_of_employees')
    if (numberOfEmployeesFilter) {
      numberOfEmployeesFilter.filterOptions = this.numberOfEmployeesOptions
    }
    const projectFundingCodesFilter = filterSet.filters.find(filter => filter.query_param === 'funding_codes')
    if (projectFundingCodesFilter) {
      projectFundingCodesFilter.filterOptions = this.projectFundingCodesOptions
    }
    const orgaLegalFormFilter = filterSet.filters.find(filter => filter.query_param === 'organization_legal_form')
    if (orgaLegalFormFilter) {
      orgaLegalFormFilter.filterOptions = this.organizationLegalFormOptions
    }
    const orgaStatutoryPurposeFilter = filterSet.filters.find(filter => filter.query_param === 'organization_statutory_purpose')
    if (orgaStatutoryPurposeFilter) {
      orgaStatutoryPurposeFilter.filterOptions = this.organizationStatutoryPurposeOptions
    }
    const organizationCityFilter = filterSet.filters.find(filter => filter.query_param === 'organization_city')
    if (organizationCityFilter) {
      organizationCityFilter.filterOptions = this.organizationCityOptions
    }
    const organizationCountryFilter = filterSet.filters.find(filter => filter.query_param === 'organization_country')
    if (organizationCountryFilter) {
      organizationCountryFilter.filterOptions = this.organizationCountryOptions
    }
    const fundingScopeTargetGroupFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_target_group')
    if (fundingScopeTargetGroupFilter) {
      fundingScopeTargetGroupFilter.filterOptions = this.fundingScopeTargetGroupOptions
    }
    const fundingScopeApproachFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_approach')
    if (fundingScopeApproachFilter) {
      fundingScopeApproachFilter.filterOptions = this.fundingScopeApproachOptions
    }
    const fundingScopeSocietyGoalsFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_society_goals')
    if (fundingScopeSocietyGoalsFilter) {
      fundingScopeSocietyGoalsFilter.filterOptions = this.fundingScopeSocietyGoalsOptions
    }
    const fundingScopeEducationGoalsFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_education_goals')
    if (fundingScopeEducationGoalsFilter) {
      fundingScopeEducationGoalsFilter.filterOptions = this.fundingScopeEducationGoalsOptions
    }
    const fundingScopeCrisisReliefFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_crisis_relief')
    if (fundingScopeCrisisReliefFilter) {
      fundingScopeCrisisReliefFilter.filterOptions = this.fundingScopeCrisisReliefOptions
    }
    const fundingScopeElderlyFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_elderly')
    if (fundingScopeElderlyFilter) {
      fundingScopeElderlyFilter.filterOptions = this.fundingScopeElderlyOptions
    }
    const fundingScopeSocialFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_social')
    if (fundingScopeSocialFilter) {
      fundingScopeSocialFilter.filterOptions = this.fundingScopeSocialOptions
    }
    const fundingScopeSportFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_sport')
    if (fundingScopeSportFilter) {
      fundingScopeSportFilter.filterOptions = this.fundingScopeSportOptions
    }
    const fundingScopeAnimalWelfareFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_animal_welfare')
    if (fundingScopeAnimalWelfareFilter) {
      fundingScopeAnimalWelfareFilter.filterOptions = this.fundingScopeAnimalWelfareOptions
    }
    const fundingScopeEnvironmentFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_environment')
    if (fundingScopeEnvironmentFilter) {
      fundingScopeEnvironmentFilter.filterOptions = this.fundingScopeEnvironmentOptions
    }
    const fundingScopeYouthWelfareFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_youth_welfare')
    if (fundingScopeYouthWelfareFilter) {
      fundingScopeYouthWelfareFilter.filterOptions = this.fundingScopeYouthWelfareOptions
    }
    const fundingScopeSdgFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_sdg')
    if (fundingScopeSdgFilter) {
      fundingScopeSdgFilter.filterOptions = this.fundingScopeSdgOptions
    }
    const fundingScopeScienceFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_science')
    if (fundingScopeScienceFilter) {
      fundingScopeScienceFilter.filterOptions = this.fundingScopeScienceOptions
    }
    const fundingScopeArtFilter = filterSet.filters.find(filter => filter.query_param === 'funding_scope_art')
    if (fundingScopeArtFilter) {
      fundingScopeArtFilter.filterOptions = this.fundingScopeArtOptions
    }
    const projectSpecialEventsFilter = filterSet.filters.find(filter => filter.query_param === 'project_special_events')
    if (projectSpecialEventsFilter) {
      projectSpecialEventsFilter.filterOptions = this.projectSpecialEventsOptions
    }
  }

  get isShowcase (): boolean {
    return this.instanceType === 'showcase_page'
  }

  get isShowcaseTemplate (): boolean {
    return this.instanceType === 'showcase_template'
  }

  closeFilterModal (): void {
    this.filterModalOpen = false
  }

  getCurrentlySelectedProjects (): SelectTableProject[] {
    return this.projects.filter(project => project.selected)
  }

  async fetchFilterSets(): Promise<void> {
    let url = ""
    if (this.isShowcase) {
      url = API_URLS.SHOWCASE_PAGE.RETRIEVE(this.showcaseSlug)
    } else if (this.isShowcaseTemplate) {
      url = API_URLS.SHOWCASE_PAGE_TEMPLATE.RETRIEVE(this.showcaseSlug)
    }
    await axios.get(url)
      .then(async (response) => {
        if (this.isShowcase) {
          this.adminFilterSets = await response.data.admin_filter_container.map((filterSet: { query_string: string, slug: string, title: string, blacklisted_projects: string[], isAdminFilterSet: boolean }) => this.parseQueryString(filterSet.slug, filterSet.title, filterSet.query_string, filterSet.blacklisted_projects, true))
        }
        this.filterSets = await response.data.filter_container.map((filterSet: { query_string: string, slug: string, title: string, blacklisted_projects: string[], isAdminFilterSet: boolean }) => this.parseQueryString(filterSet.slug, filterSet.title, filterSet.query_string, filterSet.blacklisted_projects, false))
        for (const filterSet of this.filterSets) {
          this.setAllAdminFilterValuesAsValuesOfTheUsersFilterset(this.adminFilterSets, filterSet)
        }

        this.projects = []
        const allFilterSets = this.filterSets
        allFilterSets.forEach(async (filterSet) => {
          this.setFilterOptions(filterSet)
          await this.fetchProjectsForFilterSet(filterSet)
        })
      })
      .catch(() => {
        this.$emit('show-admin-error')
      })
  }

  onlyOneFilterHasOnlyOneValue(filterset: IHdsFilterSet): boolean {
    const nonReadOnlyFilters = filterset.filters.filter(filter => !filter.readOnly)
    const singleValueFilters = nonReadOnlyFilters.filter(filter => filter.values.length === 1)
    const multiValueFilters = nonReadOnlyFilters.filter(filter => filter.values.length > 1)
    const value = singleValueFilters.length === 1 && multiValueFilters.length === 0
    return value
  }

  buildQueryString(filterSet: IHdsFilterSet): string {
    return filterSet.filters
      .filter((filter: IHdsFilter) => filter.values.length > 0)
      .map((filter: IHdsFilter) => {
        const values = filter.values.join(',')
        return `${filter.query_param}=${values}`
      })
      .join('&')
  }

  parseQueryString(filterSetSlug: string, filterSetTitle: string, queryString: string, blackListedProjects: string[], isAdminFilterSet: boolean): IHdsFilterSet {
    const filters: IHdsFilter[] = []
    const paramsFromFilterString = new URLSearchParams(queryString)
    const allAvailableQueryParams = ['organization_slug', 'funding_scope_slug', 'funding_codes', 'organizations_subcategories', 'organizations_number_of_employees',
      'organization_legal_form', 'organization_statutory_purpose', 'organization_city', 'organization_country',
      'funding_scope_target_group', 'funding_scope_approach', 'funding_scope_society_goals',
      'funding_scope_education_goals', 'funding_scope_crisis_relief', 'funding_scope_elderly',
      'funding_scope_social', 'funding_scope_sport', 'funding_scope_animal_welfare',
      'funding_scope_environment', 'funding_scope_youth_welfare', 'funding_scope_sdg',
      'funding_scope_science', 'funding_scope_art', 'project_special_events'
    ]

    paramsFromFilterString.forEach((value, key) => {
      const values = value.split(',')
      const { widget, type } = this.determineWidgetAndType(key)
      filters.push({
        label: this.getFilterLabelByParam(key),
        query_param: key,
        type,
        widget,
        multipleSelection: values.length > 1,
        filterOptions: [],
        readOnly: false,
        resultsCount: 0,
        values,
        validatorFunctions: []
      })
    })

    // add other filters that are not in the query string
    allAvailableQueryParams.forEach(param => {
      if (!filters.some(filter => filter.query_param === param)) {
        const { widget, type } = this.determineWidgetAndType(param)
        filters.push({
          label: this.getFilterLabelByParam(param),
          query_param: param,
          type,
          widget,
          multipleSelection: false,
          filterOptions: [],
          readOnly: false,
          resultsCount: 0,
          values: [],
          validatorFunctions: []
        })
      }
    })

    const filterSet: IHdsFilterSet = {
      slug: filterSetSlug,
      title: filterSetTitle,
      isAdminFilterSet,
      filters,
      projects: [],
      blacklistedProjects: blackListedProjects,
      resultsCount: 0
    }
    this.setFilterOptions(filterSet)
    return filterSet
  }

  async fetchProjectsForFilterSet(filterSet: IHdsFilterSet): Promise<void> {
    const projectSlugs = new Set(this.projects.map(project => project.slug))
    const queryString = this.buildQueryString(filterSet)
    const url = "/api/v4/projects/for-filter-container/?" + queryString
    const blackListedProjects = filterSet.blacklistedProjects.map(project => project)
    try {
      const response = await axios.get(url)
      const newProjects = response.data.results.filter((project: { slug: string }) => !projectSlugs.has(project.slug))
      filterSet.projects = newProjects
      this.projects = Array.from(new Map([...this.projects, ...newProjects].map(project => [project.slug, project])).values())
      this.blacklistedProjects = [...this.blacklistedProjects, ...blackListedProjects]
      this.projects.forEach(project => {
        const selected = !this.blacklistedProjects.includes(project.slug)
        project.selected = selected
      })
      newProjects.forEach((project: { slug: string }) => projectSlugs.add(project.slug))
    } catch {
      this.$emit('show-admin-error')
    }
  }

  async fetchAndUpdateResultsCountForFilterset() {
    const queryString = this.buildQueryString(this.currentFilterSet)
    const url = "/api/v4/projects/for-filter-container/?" + queryString
    try {
      const response = await axios.get(url)
      this.currentFilterSet.resultsCount = response.data.count
    } catch {
      this.$emit('show-admin-error')
    }
  }

  determineWidgetAndType(key: string): { widget: IHdsWidgetType, type: IHdsFilterType } {
    switch (key) {
      case 'funding_codes':
        return { widget: 'multiselect', type: 'string' }
      case 'organization_slug':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_slug':
        return { widget: 'multiselect', type: 'string' }
      case 'organizations_subcategories':
        return { widget: 'multiselect', type: 'string' }
      case 'organizations_number_of_employees':
        return { widget: 'select', type: 'string' }
      case 'organization_legal_form':
        return { widget: 'multiselect', type: 'string' }
      case 'organization_statutory_purpose':
        return { widget: 'multiselect', type: 'string' }
      case 'organization_city':
        return { widget: 'multiselect', type: 'string' }
      case 'organization_country':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_target_group':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_approach':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_society_goals':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_education_goals':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_crisis_relief':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_elderly':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_social':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_sport':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_animal_welfare':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_environment':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_youth_welfare':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_sdg':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_science':
        return { widget: 'multiselect', type: 'string' }
      case 'funding_scope_art':
        return { widget: 'multiselect', type: 'string' }
      case 'project_special_events':
        return { widget: 'multiselect', type: 'string' }
      default:
        return { widget: 'select', type: 'string' }
    }
  }

  getFilterLabelByParam (param: string): string {
    switch (param) {
      case 'funding_codes':
        return this.$gettext('Funding Codes')
      case 'organization_slug':
        return this.$gettext('Organization\'s name')
      case 'funding_scope_slug':
        return this.$gettext('Funding scope')
      case 'organizations_subcategories':
        return this.$gettext('Membership & Awards')
      case 'organizations_number_of_employees':
        return this.$gettext('Number of employees')
      case 'organization_legal_form':
        return this.$gettext('Legal form')
      case 'organization_statutory_purpose':
        return this.$gettext('Statutory purpose')
      case 'organization_city':
        return this.$gettext('City')
      case 'organization_country':
        return this.$gettext('Country')
      case 'funding_scope_target_group':
        return this.$gettext('Target group')
      case 'funding_scope_approach':
        return this.$gettext('Approach')
      case 'funding_scope_society_goals':
        return this.$gettext('Society')
      case 'funding_scope_education_goals':
        return this.$gettext('Education')
      case 'funding_scope_crisis_relief':
        return this.$gettext('Crisis relief')
      case 'funding_scope_elderly':
        return this.$gettext('Help for the elderly')
      case 'funding_scope_social':
        return this.$gettext('Social')
      case 'funding_scope_sport':
        return this.$gettext('Sport')
      case 'funding_scope_animal_welfare':
        return this.$gettext('Animal welfare')
      case 'funding_scope_environment':
        return this.$gettext('Environment and nature conservation')
      case 'funding_scope_youth_welfare':
        return this.$gettext('Children and youth welfare')
      case 'funding_scope_sdg':
        return this.$gettext('SDGs')
      case 'funding_scope_science':
        return this.$gettext('Research and science')
      case 'funding_scope_art':
        return this.$gettext('Art and culture')
      case 'project_special_events':
        return this.$gettext('Special events')
      default:
        return param
    }
  }

  async showModalForNewFilterSet() {
    const filterItems = {
      organization_slug: {
        query_param: 'organization_slug',
        multipleSelection: true,
        filterOptions: this.organizationOptions
      },
      funding_scope_slug: {
        query_param: 'funding_scope_slug',
        multipleSelection: true,
        filterOptions: this.fundingScopeOptions
      },
      organizations_subcategories: {
        query_param: 'organizations_subcategories',
        multipleSelection: true,
        filterOptions: this.membershipOptions
      },
      organizations_number_of_employees: {
        query_param: 'organizations_number_of_employees',
        multipleSelection: false,
        filterOptions: this.numberOfEmployeesOptions
      },
      organization_legal_form: {
        query_param: 'organization_legal_form',
        multipleSelection: false,
        filterOptions: this.organizationLegalFormOptions
      },
      organization_statutory_purpose: {
        query_param: 'organization_statutory_purpose',
        multipleSelection: false,
        filterOptions: this.organizationStatutoryPurposeOptions
      },
      organization_city: {
        query_param: 'organization_city',
        multipleSelection: true,
        filterOptions: this.organizationCityOptions
      },
      organization_country: {
        query_param: 'organization_country',
        multipleSelection: true,
        filterOptions: this.organizationCountryOptions
      },
      funding_scope_target_group: {
        query_param: 'funding_scope_target_group',
        multipleSelection: false,
        filterOptions: this.fundingScopeTargetGroupOptions
      },
      funding_scope_approach: {
        query_param: 'funding_scope_approach',
        multipleSelection: false,
        filterOptions: this.fundingScopeApproachOptions
      },
      funding_scope_society_goals: {
        query_param: 'funding_scope_society_goals',
        multipleSelection: false,
        filterOptions: this.fundingScopeSocietyGoalsOptions
      },
      funding_scope_education_goals: {
        query_param: 'funding_scope_education_goals',
        multipleSelection: false,
        filterOptions: this.fundingScopeEducationGoalsOptions
      },
      funding_scope_crisis_relief: {
        query_param: 'funding_scope_crisis_relief',
        multipleSelection: false,
        filterOptions: this.fundingScopeCrisisReliefOptions
      },
      funding_scope_elderly: {
        query_param: 'funding_scope_elderly',
        multipleSelection: false,
        filterOptions: this.fundingScopeElderlyOptions
      },
      funding_scope_social: {
        query_param: 'funding_scope_social',
        multipleSelection: false,
        filterOptions: this.fundingScopeSocialOptions
      },
      funding_scope_sport: {
        query_param: 'funding_scope_sport',
        multipleSelection: false,
        filterOptions: this.fundingScopeSportOptions
      },
      funding_scope_animal_welfare: {
        query_param: 'funding_scope_animal_welfare',
        multipleSelection: false,
        filterOptions: this.fundingScopeAnimalWelfareOptions
      },
      funding_scope_environment: {
        query_param: 'funding_scope_environment',
        multipleSelection: false,
        filterOptions: this.fundingScopeEnvironmentOptions
      },
      funding_scope_youth_welfare: {
        query_param: 'funding_scope_youth_welfare',
        multipleSelection: false,
        filterOptions: this.fundingScopeYouthWelfareOptions
      },
      funding_scope_sdg: {
        query_param: 'funding_scope_sdg',
        multipleSelection: false,
        filterOptions: this.fundingScopeSdgOptions
      },
      funding_scope_science: {
        query_param: 'funding_scope_science',
        multipleSelection: false,
        filterOptions: this.fundingScopeScienceOptions
      },
      funding_scope_art: {
        query_param: 'funding_scope_art',
        multipleSelection: false,
        filterOptions: this.fundingScopeArtOptions
      },
      project_special_events: {
        query_param: 'project_special_events',
        multipleSelection: false,
        filterOptions: this.projectSpecialEventsOptions
      },
      funding_codes: {
        query_param: 'funding_codes',
        multipleSelection: true,
        filterOptions: this.projectFundingCodesOptions,
        readOnly: true
      }
    }
    const filters = Object.keys(filterItems).map(key => {
      const { widget, type } = this.determineWidgetAndType(key)
      return {
        label: this.getFilterLabelByParam(key),
        ...filterItems[key],
        widget,
        type,
        resultsCount: 0,
        values: [],
        validatorFunctions: []
      }
    })
    this.currentFilterSet = {
      slug: null,
      title: 'Neues Filterset ' + (this.filterSets.length + 1),
      isAdminFilterSet: this.isShowcaseTemplate,
      filters,
      resultsCount: 0,
      projects: [],
      blacklistedProjects: []
    }
    if (this.isShowcase) {
      this.setAllAdminFilterValuesAsValuesOfTheUsersFilterset(this.adminFilterSets, this.currentFilterSet)
    }
    this.filterModalOpen = true
  }

  editFilterSet(filterSet: IHdsFilterSet) {
    this.setAllAdminFilterValuesAsValuesOfTheUsersFilterset(this.adminFilterSets, filterSet)
    this.currentFilterSet = filterSet
    this.fetchAndUpdateResultsCountForFilterset()
    this.filterModalOpen = true
  }

  async deleteFilterSet(filterSet: IHdsFilterSet): Promise<void> {
    const config = {
      data: {
        showcase_page_slug: this.showcaseSlug,
        instance_type: this.instanceType
      }
    }
    await axios.delete(API_URLS.FILTER_CONTAINER.RETRIEVE(filterSet.slug), config)
      .then(async () => {
        await this.fetchFilterSets()
        this.$emit("filter-changed")
      })
      .catch(() => {
        this.$emit('show-admin-error')
      })
  }

  deleteProject(project: { title: string, slug: string }) {
    this.projects = this.projects.filter(p => p.slug !== project.slug)
  }

  async saveFilterSet (): Promise<void> {
    let url = API_URLS.FILTER_CONTAINER.LIST
    let edit = false
    if (this.currentFilterSet.slug) {
      url = API_URLS.FILTER_CONTAINER.RETRIEVE(this.currentFilterSet.slug)
      edit = true
    }

    const data = {
      showcase_page_slug: this.showcaseSlug,
      instance_type: this.instanceType,
      filter_container: [{
        title: this.currentFilterSet.title,
        query_string: this.buildQueryString(this.currentFilterSet)
      }]
    }

    try {
      if (edit) {
        await axios.put(url, data)
      } else {
        await axios.post(url, data)
      }
      await this.fetchFilterSets()
      this.filterModalOpen = false
      this.$emit("filter-changed")
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.status === 400) {
        const title = this.$gettext('Error saving filter set')
        const message = this.$gettext('Please add a filter value and try again.')
        this.$emit('show-error', { title, message })
      } else {
        this.$emit('show-admin-error')
      }
    }
  }

  removeFilterValue(filterSet: IHdsFilterSet, filter: IHdsFilter, value: string | number | boolean) {
    if (this.onlyOneFilterHasOnlyOneValue(filterSet)) {
      this.filterTable.openDeleteModal(filterSet)
      return
    }
    const filterSetIndex = this.filterSets.findIndex(fs => fs.title === filterSet.title)
    if (filterSetIndex !== -1) {
      const filterIndex = this.filterSets[filterSetIndex].filters.findIndex(f => f.query_param === filter.query_param)
      if (filterIndex !== -1) {
        const valueIndex = this.filterSets[filterSetIndex].filters[filterIndex].values.indexOf(value)
        if (valueIndex !== -1) {
          const fs = this.filterSets[filterSetIndex]
          fs.filters[filterIndex].values.splice(valueIndex, 1)
          this.currentFilterSet = fs
          this.saveFilterSet()
        }
      }
    }
  }

  async saveProjects (filterSetSlug: string, blackListedProjects: SelectTableProject[], projects: SelectTableProject[]) {
    const url = API_URLS.FILTER_CONTAINER.ADD_PROJECTS(filterSetSlug)
    const data = {
      showcase_slug: this.showcaseSlug,
      projects: projects.map(project => project.slug),
      blacklisted_projects: blackListedProjects.map(project => project.slug)
    }
    await axios.post(url, data)
  }

  // saveProjectsForEachFilterContainer is triggered in ShowcasePageEdit, when the steps save btn is clicked
  async saveProjectsForEachFilterContainer () {
    for (const filterSet of this.filterSets) {
      const blackListedProjects = filterSet.projects.filter(project => !project.selected)
      let projects = filterSet.projects.filter(project => project.selected)
      for (const adminFilterSet of this.adminFilterSets) {
        const adminProjects = adminFilterSet.projects.filter(project => !blackListedProjects.some(bp => bp.slug === project.slug))
        projects = projects.concat(adminProjects)
      }
      await this.saveProjects(filterSet.slug, blackListedProjects, projects)
    }
  }

  async saveFilterSettings(): Promise<boolean> {
    const data: { showcase_slug: string, filter_growing: boolean, project_limit?: number } = {
      showcase_slug: this.showcaseSlug,
      filter_growing: this.localIsGrowingFilter
    }
    if (this.isSiteAdmin && this.instanceType === "showcase_template") {
      data.project_limit = this.localProjectLimit
    }
    const url = API_URLS.FILTER_CONTAINER.UPDATE_FILTER_SETTINGS
    await axios.put(url, data).then(() => {
      this.$emit('update:isGrowingFilter', this.localIsGrowingFilter)
      this.$emit('update:projectLimit', this.localProjectLimit)
      return true
    })
    return false
  }
}
