
import { Component, Prop, Vue } from 'vue-property-decorator'
import TextFieldEditor from '../../commons/fields/TextFieldEditor.vue'
import { IPSProjectForeignOrgaInfoForm, IPSProjectForeignOrgaInfoFormErrors } from '@/types/processingSteps'
import IntegerField from '../../commons/fields/IntegerField.vue'

@Component({
  components: {
    TextFieldEditor,
    IntegerField
  }
})
export default class ProjectForeignOrgaInfo extends Vue {
  @Prop() form!: IPSProjectForeignOrgaInfoForm
  @Prop() formErrors!: IPSProjectForeignOrgaInfoFormErrors
}
