
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { IPSMinimalContactPerson } from '@/types/processingSteps'
import ContactPersonPreview from '@/components/processing_steps/commons/forms/ContactPersonPreview.vue'
import EditContactPerson from '@/components/processing_steps/contact_person/EditContactPerson.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import { API_URLS } from '@/utils/helpers'
import GenericTable from '@/components/GenericTable.vue'
import axios from 'axios'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import AddButton from '@/components/buttons/AddButton.vue'
import RemoveButton from '@/components/buttons/RemoveButton.vue'
import DeleteModal from '@/components/modals/DeleteModal.vue'
import ConfirmModal from "@/components/processing_steps/commons/modals/ConfirmModal.vue"
import IconButton from '@/components/buttons/IconButton.vue'

@Component({
  components: {
    ConfirmModal,
    ContactPersonPreview,
    EditContactPerson,
    EditButton,
    GenericTable,
    DeleteButton,
    DeleteModal,
    AddButton,
    RemoveButton,
    IconButton
  }
})
export default class ContactPersonSelect extends Vue {
  @Ref('editContactPerson') editContactPerson!: EditContactPerson
  @Ref('showTable') showTable!: GenericTable
  @Ref('addTable') addTable!: GenericTable
  @Prop() contactPersonType!: 'public' | 'internal' | 'data_admin'
  @Prop() stepType!: 'project' | 'organization' | 'funding_scope'
  @Prop() instanceSlug!: string

  showPreviewModal = false
  previewContactPersonSlug: string | null = null
  showEditModal = false
  editContactPersonUrl: string | null = null
  showDeleteModal = false
  selectedContactPerson: IPSMinimalContactPerson | null = null
  showAddTable = false

  showShowTableActionButton = false

  apiUrl = API_URLS.CONTACT_PERSON.LIST

  showTableFields = [
    {
      key: 'addressable_name',
      label: this.$gettext('Name'),
      sortable: true
    },
    {
      key: 'fixed_action_col',
      label: this.$gettext('Actions')
    }
  ]

  get apiUrlExtraParamsForNotSelected () {
    return {
      for_step: true,
      step_type: this.stepType,
      step_instance_slug: this.instanceSlug,
      contact_person_type: this.contactPersonType,
      exclude_own: true
    }
  }

  get apiUrlExtraParamsForSelected () {
    return {
      for_step: true,
      step_type: this.stepType,
      step_instance_slug: this.instanceSlug,
      contact_person_type: this.contactPersonType,
      exclude_own: false
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateShowTableItemCount (results: any) {
    if (results && results.length === 0) {
      this.showShowTableActionButton = true
    } else {
      this.showShowTableActionButton = false
    }
  }

  openPreviewModal (contactPerson: IPSMinimalContactPerson) {
    this.previewContactPersonSlug = contactPerson.slug
    this.showPreviewModal = true
  }

  openEditModal (contactPerson: IPSMinimalContactPerson | null) {
    this.showAddTable = false
    if (contactPerson === null) {
      this.editContactPersonUrl = null
    } else {
      this.editContactPersonUrl = "/api/v4/contactpersons/" + contactPerson.slug + "/"
    }
    this.showEditModal = true
  }

  closeEditModal (): void {
    this.editContactPersonUrl = null
    this.showEditModal = false
  }

  async updateContactPerson (redirectToNextStep: boolean): Promise<void> {
    const saved = await this.editContactPerson.saveBaseInformation(this.editContactPerson.activeStep, redirectToNextStep)
    if (saved) {
      this.showEditModal = false
      this.showTable.loadItems()
    }
    this.$emit("contact-person-updated")
  }

  openAddTable () {
    this.showAddTable = true
  }

  onCreateOrUpdate (saved: boolean) {
    if (saved && this.showTable && this.addTable) {
      this.showTable.loadItems()
      this.addTable.loadItems()
    }
  }

  openDeleteModal (contactPerson: IPSMinimalContactPerson) {
    this.selectedContactPerson = contactPerson
    this.showDeleteModal = true
  }

  async deleteContactPerson (slug: string) : Promise<void> {
    const url = API_URLS.CONTACT_PERSON.RETRIEVE(slug)
    await axios.delete(url)
    this.showDeleteModal = false
    this.addTable.loadItems()
  }

  async addContactPerson (contactPerson: IPSMinimalContactPerson): Promise<void> {
    await this.postData([contactPerson.slug], false).then(() => {
      this.$emit("contact-person-updated")
    })
    this.showTable.loadItems()
    if (this.contactPersonType === 'public') {
      this.showAddTable = false
    }
    if (this.contactPersonType === 'internal') {
      this.addTable.loadItems()
    }
  }

  async removeContactPerson (contactPerson: IPSMinimalContactPerson): Promise<void> {
    await this.postData([contactPerson.slug], true)
    this.showTable.loadItems()
  }

  async postData (contactPersonSlugs: string[], remove): Promise<void> {
    const data = {
      contact_person_slugs: contactPersonSlugs,
      step_type: this.stepType,
      step_instance_slug: this.instanceSlug,
      contact_person_type: this.contactPersonType,
      remove
    }
    await axios.post(API_URLS.CONTACT_PERSON.ADD_TO_INSTANCE, data)
  }
}
