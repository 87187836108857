
import { Component, Mixins, Ref } from 'vue-property-decorator'
import axios from 'axios'
import StepNavigation from '@/components/processing_steps/StepNavigation.vue'
import FormButtons from '@/components/processing_steps/FormButtons.vue'
import FormTitle from '@/components/processing_steps/FormTitle.vue'
import AddressForm from '@/components/processing_steps/commons/forms/AddressForm.vue'
import StepContainer from '../commons/StepContainer.vue'
import FormContainer from '../commons/forms/FormContainer.vue'
import ContactPersonSelect from '../commons/forms/ContactPersonSelect.vue'
import { API_URLS } from '@/utils/helpers'
import ProcessingStepMixin from '../ProcessingStepMixin.vue'
import PSBankAccountForm from '../commons/forms/PSBankAccountForm.vue'
import { IPhoto } from '@/types/photos'
import DocumentField from '../commons/fields/DocumentField.vue'
import {
  IPSAddressForm,
  IPSAddressFormErrors,
  IPSEditFundingScope,
  IPSFundingScopeBaseForm,
  IPSFundingScopeBaseFormErrors,
  IProcessingStep
} from '@/types/processingSteps'
import BaseInformationForm from '@/components/processing_steps/funding_scope/forms/BaseInformationForm.vue'
import TextFieldEditor from '../commons/fields/TextFieldEditor.vue'
import NavigationLinks from '@/components/navigation_link/NavigationLinks.vue'
import FundingScopeSummaryMixin from './mixins/FundingScopeSummaryMixin.vue'
import AccountPublishFundingScopeForm from './completition_forms/AccountPublishFundingScopeForm.vue'
import FundingScopeIsPublicForm from './completition_forms/FundingScopeIsPublicForm.vue'
import { INavigationLink } from "@/types/foerderApp"
import SubCategoryForm from '../commons/forms/SubCategoryForm.vue'

@Component({
  components: {
    SubCategoryForm,
    StepNavigation,
    FormTitle,
    FormButtons,
    AddressForm,
    StepContainer,
    FormContainer,
    ContactPersonSelect,
    PSBankAccountForm,
    DocumentField,
    BaseInformationForm,
    TextFieldEditor,
    NavigationLinks,
    AccountPublishFundingScopeForm,
    FundingScopeIsPublicForm
  }
})
export default class EditFundingScope extends Mixins(ProcessingStepMixin, FundingScopeSummaryMixin) {
  @Ref() contactPersonSelect!: ContactPersonSelect
  @Ref() subCategoryForm!: SubCategoryForm
  projectCategoryAvailability = false
  fundingScopeSlug: string | null = null
  fundingScope: IPSEditFundingScope | null = null
  availableOrganizations: { value: string, text: string }[] = []
  // todo set extistinglogos like here and in EditProject.vue
  // check the FundingScopeEditSerializer and add the required fields for logo
  exitstingDocuments: IPhoto[] = []
  existingPhotos: IPhoto[] = []
  existingLogos: IPhoto[] = []
  termsAccepted = false

  isCreate = true

  bankAccountForm = {
    owner: "",
    bank_name: "",
    iban: "",
    bic: "",
    termsAccepted: false
  }

  bankAccountFormErrors = {
    owner: "",
    bank_name: "",
    iban: "",
    bic: ""
  }

  whatWeDoForm = {
    description_1: "",
    url_1: "",
    url_2: ""
  }

  whatWeDoFormErrors = {
    description_1: "",
    url_1: "",
    url_2: ""
  }

  fundingScopeForm: IPSFundingScopeBaseForm = {
    name: "",
    related_organization: ""
  }

  fundingScopeFormErrors: IPSFundingScopeBaseFormErrors = {
    name: ""
  }

  addressForm: IPSAddressForm = {
    street: "",
    additional_information: "",
    postal_code: "",
    city: "",
    region: "",
    country: "DE"
  }

  addressFormErrors: IPSAddressFormErrors = {
    street: "",
    postal_code: "",
    city: "",
    country: ""
  }

  firstStep: IProcessingStep = {
    key: "basic",
    title: "Basisinformationen",
    completed: false,
    required: true,
    active: true,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  secondStep: IProcessingStep = {
    key: "what_we_do",
    title: this.$gettext("What we do"),
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  thirdStep: IProcessingStep = {
    key: "contact_persons",
    title: "Ansprechpartner",
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  fourthStep: IProcessingStep = {
    key: "assignment",
    title: this.$gettext("Categories and tags"),
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  fifthStep: IProcessingStep = {
    key: "bank_account",
    title: "Bankverbindung",
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  sixthStep: IProcessingStep = {
    key: "completition",
    title: this.$gettext("Release"),
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: this.isSiteAdmin
    }
  }

  get fundingScopeHasBankData (): boolean {
    return !!(this.fundingScope && this.fundingScope.owner && this.fundingScope.bank_name && this.fundingScope.iban && this.fundingScope.bic)
  }

  get addContactPersonToInstanceUrl(): string {
    return API_URLS.CONTACT_PERSON.ADD_TO_INSTANCE
  }

  get fundingScopeAddressComplete (): boolean {
    return !!(this.fundingScope && this.fundingScope.street && this.fundingScope.postal_code && this.fundingScope.city && this.fundingScope.country)
  }

  get basicInfoCompleted (): boolean {
    return this.fundingScope && this.fundingScope.name && this.fundingScopeAddressComplete
  }

  get basicInfoFormCompleted (): boolean {
    return !!this.fundingScopeForm.name && !!this.fundingScopeForm.related_organization
  }

  async mounted (): Promise<void> {
    await this.setProjectCategoryAvailability()
    this.isCreate = window.location.pathname.endsWith("create/")
    await this.setAllData()
  }

  async setAllData (): Promise<void> {
    await this.fetchFundingScope()
    this.setSteps()
    this.setActiveStep(null)
    await this.fetchAvailableOrganizations()
    this.setBasicInfoForm()
    this.updateBreadCrumbs()
    this.checkAndValidateSteps()
    this.termsAccepted = this.fundingScope?.is_public || false
  }

  async assignmentIsValid () {
    if (!this.fundingScope) {
      return false
    }
    const subCategoryForm = new SubCategoryForm({
      propsData: {
        projectCategories: this.fundingScope.project_categories,
        instance: { type: 'funding_scope', slug: this.fundingScope.slug }
      }
    })
    subCategoryForm.$mount()
    await subCategoryForm.setCategoriesAndShownCategories()
    const tagsValid = await subCategoryForm.stepIsValid()
    await this.$nextTick()
    const isValid = tagsValid
    subCategoryForm.$destroy()
    return isValid
  }

  checkAndValidateSteps (): void {
    this.steps.forEach((step: IProcessingStep) => {
      this.checkAndValidateStep(step)
    })
  }

  async checkAndValidateStep(step: IProcessingStep) {
    if (step.required === false) {
      this.setAdditionalStepValid(step)
      return
    }
    if (step.key === "basic") {
      if (this.basicInfoCompleted) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === "contact_persons") {
      if (this.fundingScope && this.fundingScope.has_public_contact_person) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === "bank_account") {
      if (this.fundingScope && this.fundingScopeHasBankData) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === 'assignment') {
      if (await this.assignmentIsValid()) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === "what_we_do") {
      if (this.whatWeDoForm.description_1) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === 'completition') {
      if (this.fundingScope?.is_public) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    }
  }

  get requiredFieldsComplete (): boolean {
    const requiredStepsKeys = ["basic", "what_we_do", "contact_persons", "bank_account", "assignment"]
    for (const key of requiredStepsKeys) {
      const step = this.steps.find(step => step.key === key)
      if (!step || !step.completed) {
        return false
      }
    }
    return true
  }

  async handleContactPersonsUpdated (): Promise<void> {
    await this.fetchFundingScope()
    this.checkAndValidateSteps()
  }

  updateBreadCrumbs() {
    // todo do the same for the other components
    // todo add urls
    const tableUrl = "/account/funding-scopes/"
    const createUrl = ""
    let breadcrumbs = []
    const fundingScopeIcon = "fundingScopeIcon"
    if (this.isCreate) {
      breadcrumbs = [
        { title: this.$gettext("Funding scopes"), url: tableUrl, icon: fundingScopeIcon },
        { title: this.$gettext("Create"), url: createUrl }
      ]
    } else {
      breadcrumbs = [
        { title: this.$gettext("Funding scopes"), url: tableUrl, icon: fundingScopeIcon },
        { title: this.fundingScope.name, url: '/processing-steps/' }
      ]
    }
    this.$emit("breadcrumbs-set", breadcrumbs) // emits to ProcessingSteps.vue to set the new breadcrumbs
  }

  get documentUploadUrl (): string {
    if (!this.fundingScope) { return "" }
    return API_URLS.PHOTOS.UPLOAD_PHOTO(this.fundingScope.document_album_slug)
  }

  get photoUploadUrl (): string {
    if (!this.fundingScope) { return "" }
    return API_URLS.PHOTOS.UPLOAD_PHOTO(this.fundingScope.photo_album_slug)
  }

  get logoUploadUrl (): string {
    if (!this.fundingScope) { return "" }
    return API_URLS.PHOTOS.UPLOAD_PHOTO(this.fundingScope.logo_album_slug)
  }

  setBasicInfoForm () {
    // todo if not create, set the oter fields for first step
    // add functions to set the forms for the other steps
    let relatedOrganization = this.availableOrganizations.length > 0 ? this.availableOrganizations[0].value : ""
    if (this.fundingScope && this.fundingScope.related_organization && this.fundingScope.related_organization.slug !== "") {
      relatedOrganization = this.fundingScope.related_organization.slug
    }
    this.fundingScopeForm.related_organization = relatedOrganization
    if (this.fundingScope) {
      this.fundingScopeForm = {
        name: this.fundingScope.name,
        related_organization: relatedOrganization
      }
      this.whatWeDoForm = {
        description_1: this.fundingScope.description_1 || "",
        url_1: this.fundingScope.url_1 || "",
        url_2: this.fundingScope.url_2 || ""
      }
    }
  }

  setAddressForm () {
    if (this.fundingScope) {
      this.addressForm.street = this.fundingScope.street
      this.addressForm.additional_information = this.fundingScope.additional_information
      this.addressForm.postal_code = this.fundingScope.postal_code
      this.addressForm.city = this.fundingScope.city
      this.addressForm.region = this.fundingScope.region
      this.addressForm.country = this.fundingScope.country ? this.fundingScope.country : "DE"
    }
  }

  setBankForm () {
    if (this.fundingScope) {
      const allFieldsFilled = !!this.fundingScope.owner && !!this.fundingScope.bank_name && !!this.fundingScope.iban && !!this.fundingScope.bic
      this.bankAccountForm.owner = this.fundingScope.owner
      this.bankAccountForm.bank_name = this.fundingScope.bank_name
      this.bankAccountForm.iban = this.fundingScope.iban
      this.bankAccountForm.bic = this.fundingScope.bic
      this.bankAccountForm.termsAccepted = allFieldsFilled
    }
  }

  setExistingDocuments () {
    if (this.fundingScope) {
      this.exitstingDocuments = this.fundingScope.documents
    }
  }

  setExistingPhotos () {
    if (this.fundingScope) {
      this.existingPhotos = this.fundingScope.photos
    }
  }

  setExistingLogos () {
    if (this.fundingScope) {
      this.existingLogos = this.fundingScope.logos
    }
  }

  async fetchAvailableOrganizations (): Promise<void> {
    await axios.get(API_URLS.PROCESSING_STEPS.AVAILABLE_ORGANIZATIONS).then((response) => {
      this.availableOrganizations = response.data
    }).catch(() => {
      this.showError(null)
    })
  }

  getFundingScopeSlugFromUrl (): string {
    return window.location.pathname.split("funding-scopes/")[1].split("/update/")[0]
  }

  async fetchFundingScope (): Promise<void> {
    if (this.isCreate) {
      return
    }
    let url = "/api/v4/funding-scopes/"
    this.fundingScopeSlug = this.getFundingScopeSlugFromUrl()
    url += this.fundingScopeSlug + "/edit-data/"

    await axios.get(url).then((response) => {
      this.fundingScope = response.data
      this.setAddressForm()
      this.setBankForm()
      this.setExistingDocuments()
      this.setExistingPhotos()
      this.setExistingLogos()
    }).catch(() => {
      this.showError(null)
    })
  }

  setSteps (): void {
    this.steps = [
      this.firstStep,
      this.secondStep,
      this.thirdStep,
      ...(this.projectCategoryAvailability ? [this.fourthStep] : []),
      this.fifthStep,
      this.sixthStep
    ]
  }

  async setProjectCategoryAvailability() {
    await axios.get("/api/v4/projectcategories/?available_for=funding_scopes").then((response) => {
      this.projectCategoryAvailability = response.data.results.length > 0
    })
  }

  resetFormErrors(): void {
    this.fundingScopeFormErrors = {
      name: ""
    }
  }

  isActiveStep (step: IProcessingStep): boolean {
    return this.activeStep === step
  }

  changeStep (step: IProcessingStep): void {
    this.activeStep = step
  }

  handlePhotosChanged (fundingScope: IPSEditFundingScope): void {
    this.fundingScope = fundingScope
  }

  getUpdateUrl (slug: string): string {
    if (this.isSiteAdmin) {
      return "/site-admin/funding-scopes/" + slug + "/update/"
    } else {
      return "/account/funding-scopes/" + slug + "/update/"
    }
  }

  async createFundingScope (): Promise<boolean> {
    let created = false
    const url = "/api/v4/funding-scopes/"
    await axios.post(url, this.fundingScopeForm).then(async (response) => {
      created = true
      this.isCreate = false
      this.showSuccessMsg(null)
      this.fundingScopeSlug = response.data.slug
      const addressSaved = await this.saveAddress()
      if (!addressSaved) {
        this.showError({
          title: this.$gettext("Error"),
          message: this.$gettext("An error occurred while saving the address.")
        })
      }
      const updateUrl = this.getUpdateUrl(this.fundingScopeSlug)
      window.history.pushState({}, "", updateUrl)
      await this.setAllData()
    }).catch((error) => {
      this.showError(null)
      const errorMessages = error.response.data
      this.fundingScopeFormErrors = {
        name: errorMessages.name ? errorMessages.name[0] : ""
      }
    })
    return created
  }

  async updateFundingScopeBaseInfo (): Promise<boolean> {
    let saved = false
    this.resetFormErrors()
    let url = "/api/v4/funding-scopes/"
    const currentUrl = window.location.pathname
    if (currentUrl.endsWith("update/")) {
      const slug = currentUrl.split("funding-scopes/")[1].split("/update/")[0]
      url += slug + "/"
      await axios.put(url, this.fundingScopeForm).then(async () => {
        saved = true
        // todo remove emit
        await this.saveAddress()
        this.$emit("message", { type: "success", title: "Förderbereich gespeichert", message: "Die Änderungen wurden erfolgreich gespeichert." })
      }).catch((error) => {
        const errorMessages = error.response.data
        this.fundingScopeFormErrors = {
          name: errorMessages.name ? errorMessages.name[0] : ""
        }
      })
      return saved
    }
  }

  async saveAddress (): Promise<boolean> {
    let saved = false
    const apiUrl = this.getPostUrlForAddressForm(this.fundingScopeSlug)
    await axios.put(apiUrl, this.addressForm).then(async () => {
      saved = true
    }).catch((error) => {
      this.setFormErrorsFromErrorResponse(error, this.addressFormErrors)
    })
    return saved
  }

  async saveBankAccount (): Promise<boolean> {
    if (!this.bankAccountForm.termsAccepted) {
      this.showError({
        title: this.$gettext("Error"),
        message: this.$gettext("Please accept the terms and conditions.")
      })
      return false
    }
    let saved = false
    const apiUrl = this.getPostUrlForBankForm(this.fundingScopeSlug)
    await axios.post(apiUrl, this.bankAccountForm).then(async () => {
      saved = true
      this.showSuccessMsg(null)
      await this.fetchFundingScope()
      this.checkAndValidateStep(this.activeStep)
    }).catch((error) => {
      this.showError(null)
      this.setFormErrorsFromErrorResponse(error, this.bankAccountFormErrors)
    })
    return saved
  }

  getPostUrlForAddressForm (slug: string) {
    if (!this.isSiteAdmin) {
      return "/api/v4/funding-scopes/" + slug + "/?own&step=address"
    } else {
      return "/api/v4/funding-scopes/" + slug + "/?step=address"
    }
  }

  getPostUrlForWhatWeDoForm (slug: string) {
    if (!this.isSiteAdmin) {
      return "/api/v4/funding-scopes/" + slug + "/?own&step=what_we_do"
    } else {
      return "/api/v4/funding-scopes/" + slug + "/?step=what_we_do"
    }
  }

  getPostUrlForBankForm (slug: string) {
    if (!this.isSiteAdmin) {
      return "/api/v4/funding-scopes/" + slug + "/add-bank-data/"
    } else {
      return "/api/v4/funding-scopes/" + slug + "/add-bank-data/"
    }
  }

  handlePhotoUpdated (): void {
    this.fetchFundingScope()
  }

  async saveWhatWeDo (): Promise<boolean> {
    let saved = false
    const apiUrl = this.getPostUrlForWhatWeDoForm(this.fundingScopeSlug)
    await axios.put(apiUrl, this.whatWeDoForm).then(async () => {
      this.showSuccessMsg(null)
      saved = true
    }).catch((error) => {
      this.setFormErrorsFromErrorResponse(error, this.whatWeDoFormErrors)
    })
    return saved
  }

  async saveBtnClicked (currentStep: IProcessingStep): Promise<boolean> {
    let saved = false
    if (currentStep.key === "basic") {
      if (this.isCreate) {
        saved = await this.createFundingScope()
      } else {
        saved = await this.updateFundingScopeBaseInfo()
      }
    } else if (currentStep.key === "what_we_do") {
      saved = await this.saveWhatWeDo()
      this.checkAndValidateStep(currentStep)
    } else if (currentStep.key === "bank_account") {
      saved = await this.saveBankAccount()
    } else if (currentStep.key === "assignment") {
      saved = this.subCategoryForm.stepIsValid()
      this.checkAndValidateStep(currentStep)
      if (!saved) {
        const error = this.subCategoryForm.getError()
        this.showError(error)
      }
    } else if (["documents", "photos", "logo", "contact_persons"].includes(currentStep.key)) {
      saved = true
    }
    return saved
  }

  async saveAndNextBtnClicked (): Promise<void> {
    const saved = await this.saveBtnClicked(this.activeStep)
    if (saved) {
      this.gotoNextStepOrFinish(this.steps, this.activeStep)
    }
  }

  gotoStep (step: IProcessingStep): void {
    this.checkAndValidateStep(this.activeStep)
    if (this.basicInfoFormCompleted) {
      this.setActiveStep(step)
    } else {
      this.showError(null)
    }
  }

  get tableUrl (): string {
    return this.isSiteAdmin ? "/site-admin/funding-scopes/" : "/account/funding-scopes/"
  }

  redirectToTable (): void {
    window.location.href = this.tableUrl
  }

  finishBtnClicked (): void {
    this.redirectToTable()
  }

  enterOrganizationBankData (): void {
    this.bankAccountForm.owner = this.fundingScope.related_organization.owner
    this.bankAccountForm.bank_name = this.fundingScope.related_organization.bank_name
    this.bankAccountForm.iban = this.fundingScope.related_organization.iban
    this.bankAccountForm.bic = this.fundingScope.related_organization.bic
  }

  enterOrganizationsAddress (): void {
    const relatedOrgaSlug = this.fundingScopeForm.related_organization
    let url = API_URLS.ORGANIZATIONS.RETRIEVE(relatedOrgaSlug)
    if (!this.isSiteAdmin) {
      url += "?own"
    }
    axios.get(url).then((response) => {
      const relatedOrga = response.data
      this.addressForm = {
        street: relatedOrga.street,
        additional_information: relatedOrga.additional_information,
        postal_code: relatedOrga.postal_code,
        region: relatedOrga.region,
        city: relatedOrga.city,
        country: relatedOrga.country
      }
    }).catch(() => {
      this.showError(null)
    })
  }

  async onLogoUploadClicked (): Promise<void> {
    if (!this.fundingScopeForm.name) {
      this.showError({
        title: this.$gettext("Error"),
        message: this.$gettext("Please enter a name for the funding scope first.")
      })
    }
    if (!this.fundingScope) {
      await this.createFundingScope()
    }
  }

  updateLinks(updatedLinks: INavigationLink[]) {
    this.$set(this.fundingScope, 'links', updatedLinks)
  }

  async setFundingScopePublic (): Promise<void> {
    if (!this.termsAccepted) {
      this.showError({
        title: this.$gettext("Error"),
        message: this.$gettext("Please accept the terms and conditions.")
      })
      return
    }
    await axios.post(API_URLS.FUNDING_SCOPE.TOGGLE_PUBLIC(this.fundingScopeSlug)).then(() => {
      this.showSuccessMsg(null)
      this.fetchFundingScope()
    }).catch(() => {
      this.showError(null)
    })
  }

  openCreateContactModal (): void {
    this.contactPersonSelect.openEditModal(null)
  }
}
