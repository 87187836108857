
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BaseInformationValidationMixin extends Vue {
  selectedFundingScopesSlug = ""
  financeForm = {
    goal_in_cents: 0
  }

  financeFormErrors = {
    goal_in_cents: ""
  }

  projectBaseInformationForm = {
    title: "",
    category: "",
    organization: {}
  }

  projectLocationForm = {
    street: "",
    postal_code: "",
    city: "",
    country: "",
    region: '',
    additional_information: "",
    longitude: 0,
    latitude: 0
  }

  get baseInformationIsValid () {
    if (!(this.financeForm.goal_in_cents > 0)) {
      this.financeFormErrors.goal_in_cents = this.$gettext('Please enter a goal amount higher than 0')
      return false
    }
    const requiredFields = ["title", "category", "organization",
      "street", "postal_code", "city", "country", "goal_in_cents"
    ]
    const data = {
      ...this.projectBaseInformationForm,
      ...this.projectLocationForm,
      ...this.financeForm
    }
    const hasFundingScope = !!this.selectedFundingScopesSlug && this.selectedFundingScopesSlug !== "undefined"
    return requiredFields.every(field => data[field]) && hasFundingScope
  }
}
