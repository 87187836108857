
import { Vue, Component, Prop } from 'vue-property-decorator'
import CompletitionLayout from "@/components/processing_steps/commons/CompletitionLayout.vue"
import FormContainer from "@/components/processing_steps/commons/forms/FormContainer.vue"
import HdsCheckboxCross from "@/components/processing_steps/commons/fields/HdsCheckboxCross.vue"

@Component({
  components: { HdsCheckboxCross, FormContainer, CompletitionLayout }
})
export default class TermsForm extends Vue {
  @Prop({ default: false }) termsAccepted!: boolean
  @Prop({ default: '' }) description!: string
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) checkboxText!: string

  get termsAndPrivacyHtml (): string {
    return this.checkboxText || this.$gettext('I agree to the <a href="https://www.hausdesstiftens.org/rechtliches/nutzungsbedingungen/">terms of use</a> and the <a href="https://www.hausdesstiftens.org/rechtliches/datenschutz/">privacy policy</a>.')
  }

  updateTermsAccepted(value: boolean): void {
    this.$emit('update:termsAccepted', value)
  }
}
