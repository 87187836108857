
import CheckboxField from '@/components/processing_steps/commons/fields/CheckboxField.vue'
import FormContainer from '@/components/processing_steps/commons/forms/FormContainer.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CompletitionLayout from '@/components/processing_steps/commons/CompletitionLayout.vue'
import TermsForm from "@/components/processing_steps/commons/forms/TermsForm.vue"
import HdsCheckboxCross from "@/components/processing_steps/commons/fields/HdsCheckboxCross.vue"

@Component({
  components: {
    HdsCheckboxCross,
    TermsForm,
    FormContainer,
    CheckboxField,
    CompletitionLayout
  }
})
export default class AccountPublishOrgaForm extends Vue {
  @Prop() termsAccepted!: boolean
  @Prop() legalNoticeAccepted!: boolean
  @Prop() isDraft!: boolean
  @Prop() isComplete!: boolean
  @Prop() summaryItems!: { title: string, items: { title: string, values: string[] }[]}[]
  @Prop() termsAndPrivacyHtml!: string
  @Prop() organizationTitle!: string

  get isCompleteButNotSubmitted (): boolean {
    return this.isComplete && this.isDraft
  }

  get legalNoticeText(): string {
    return this.$gettextInterpolate(
      this.$gettext(
        'False information may have legal consequences. In particular, %{organization} reserves the right to take legal action, assert claims for damages and inform the competent authorities in the event of deception, misuse or other irregularities. ' +
        'In addition, false statements may be prosecuted under criminal law. This applies in particular to fraud and tax evasion. In addition to criminal sanctions, civil law claims may also arise, including the repayment of unlawfully received funds or compensation. ' +
        'We expressly point out that any intentional or negligent misrepresentation violates the existing obligation to comply with legal regulations and may result in the loss of recognition as a non-profit organisation / NGO.'
      ),
      { organization: this.organizationTitle }
    )
  }

  get statusExplanation (): string {
    return this.isComplete
      ? this.$gettext('The organizational data is complete. Do you want to submit the organization for review by Haus des Stiftens now? You can create funding areas and funding requirements until they have been approved by Haus des Stiftens, but you cannot publish them yet.')
      : this.$gettext('The organizational data is incomplete and you can only create funding areas and funding requirements once you have entered the organizational data in full.')
  }
}
