
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { VueEditor } from "vue2-editor"
@Component({
  components: {
    VueEditor
  }
})
export default class TextFieldEditor extends Vue {
  @Prop({ default: '' }) value!: string
  @Prop({ default: '' }) error!: string
  @Prop({ default: null }) label!: string
  @Prop({ default: false }) required!: boolean
  @Prop({ default: '' }) placeholder !: string
  @Prop() minLength!: number
  @Prop() maxLength!: number

  state: boolean | null = null
  content = ""
  customToolbar = [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ color: [] }, { background: [] }]
  ]

  get charCount(): number {
    const strippedContent = this.content.replace(/<\/?[^>]+(>|$)/g, '')
    return strippedContent.length
  }

  get charCountInfoClass(): string {
    const charCountMatches = this.charCount >= this.minLength && this.charCount <= this.maxLength
    return charCountMatches ? 'text-success' : 'text-danger'
  }

  mounted() {
    this.content = this.value
  }

  @Watch('value')
  onValuePropChanged(newValue: string) {
    this.content = newValue
  }

  @Watch('error')
  onPropertyChanged(value: string) {
    this.state = value ? false : null
  }

  @Watch('content')
  onValueChanged(value: string) {
    this.$emit('update:value', value)
    this.state = value ? false : null
  }
}
