
import axios from 'axios'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import SfAlert from '@/components/SfAlert.vue'
import UserMixin from '@/mixins/UserMixin'
import { ExploreModule } from '@/store/modules/explore'
import { ICurrencyDisplaySettings, IProjectDetailSettings } from '@/types/cms'
import { ICoFunding } from '@/types/finances'
import { IExploreProjectDetail, IDonationResult } from '@/types/projects'
import { API_URLS } from "@/utils/helpers"
import DonationSuccessModal from '../modals/DonationSuccessModal.vue'
import CoFundingInfo from './CoFundingInfo.vue'
import ContactPerson from './ContactPerson.vue'
import DonationInfo from './DonationInfo.vue'
import InfoArea from './InfoArea.vue'
import PrivateDonationArea from './PrivateDonationArea.vue'
import ProjectOverview from './ProjectOverview.vue'
import SocialMediaArea from './SocialMediaArea.vue'
import SupportProjectArea from './SupportProjectArea.vue'
import VotingArea from './VotingArea.vue'
import VotingInfo from './VotingInfo.vue'
import SocialMediaAreaFoerderApp from './hds/SocialMediaAreaFoerderApp.vue'
import { ITab } from '@/types/detailPages'
import TabContainer from '../detail_pages/TabContainer.vue'

@Component({
  name: 'project-detail',
  components: {
    ProjectOverview,
    DonationInfo,
    VotingArea,
    SupportProjectArea,
    VotingInfo,
    SfAlert,
    PrivateDonationArea,
    ContactPerson,
    SocialMediaArea,
    SocialMediaAreaFoerderApp,
    CoFundingInfo,
    InfoArea,
    DonationSuccessModal,
    TabContainer
  }
})
export default class ExploreDetail extends Mixins(UserMixin) {
  @Prop({ default: false }) isOrganization!: boolean
  project: IExploreProjectDetail = null
  newVotes = 0
  donationResult: IDonationResult = null
  donationSuccessModalOpen = false
  tabsSet = false

  tabs: ITab[] = [
    {
      key: "what_we_need",
      title: this.$gettext("What we need"),
      is_active: true,
      color: "",
      tab_settings: {
        show_images: true,
        show_title_above_images: true
      },
      tab_content: {
        title: "",
        text: "",
        photos: [],
        links: [],
        documents: [],
        contact_person: null,
        contact_person_logo: "",
        funding_goal: ""
      }
    },
    {
      key: "what_we_do",
      title: this.$gettext("What we do"),
      is_active: false,
      color: "",
      tab_settings: {
        show_images: true,
        show_title_above_images: true
      },
      tab_content: {
        title: "",
        text: "",
        photos: [],
        links: [],
        documents: [],
        contact_person: null,
        contact_person_logo: ""
      }
    },
    {
      key: "who_we_are",
      title: this.$gettext("Who we are"),
      is_active: false,
      color: "",
      tab_settings: {
        show_images: true,
        show_title_above_images: true
      },
      tab_content: {
        title: "",
        text: "",
        photos: [],
        links: [],
        documents: [],
        contact_person: null,
        contact_person_logo: ""
      }
    }
  ]

  get projectDetailSettings (): IProjectDetailSettings {
    return ExploreModule.projectDetailSettings
  }

  get coFundings (): ICoFunding[] {
    return ExploreModule.coFundings
  }

  get isPreview (): boolean {
    return !!this.$route.query?.preview_token
  }

  get currencyDisplay (): ICurrencyDisplaySettings {
    return this.projectDetailSettings?.currencyDisplay
  }

  get orgaColor (): string {
    const baseColor = getComputedStyle(document.documentElement).getPropertyValue('--base-ci').trim()
    const orgaColor = this.project?.organization.color || baseColor
    return orgaColor
  }

  get showPrivateDonationArea (): boolean {
    return this.projectDetailSettings?.enablePrivateDonations && !this.isPreview && !this.project?.is_status_archived && !this.project?.organization.in_foreign_country
  }

  async mounted () {
    await this.loadProject()
    const projectTab = this.getTabByKey('what_we_need')
    const fundingScopeTab = this.getTabByKey('what_we_do')
    const organizationTab = this.getTabByKey('who_we_are')

    const fundingScopeLogo = this.project.funding_scope?.logos && this.project.funding_scope.logos.length > 0 ? this.project.funding_scope.logos[0].src : null
    const organizationLogo = this.project.organization?.logo

    projectTab.tab_content.title = this.project.title
    projectTab.tab_content.text = this.project.what_we_need.description
    projectTab.tab_content.photos = this.project.photos
    projectTab.tab_content.links = this.project.what_we_need.links
    projectTab.tab_content.documents = this.project.what_we_need.documents
    projectTab.tab_content.contact_person = this.project.contact_person
    projectTab.tab_content.contact_person_logo = organizationLogo
    projectTab.tab_content.funding_goal = this.project.current_goal.in_currency_display

    fundingScopeTab.tab_content.title = this.project.funding_scope.name
    fundingScopeTab.tab_content.text = this.project.funding_scope.description_1
    fundingScopeTab.tab_content.photos = this.project.funding_scope.photos
    fundingScopeTab.tab_content.links = this.project.funding_scope.links
    fundingScopeTab.tab_content.documents = this.project.funding_scope.documents
    fundingScopeTab.tab_content.contact_person = this.project.funding_scope.contact_person
    fundingScopeTab.tab_content.contact_person_logo = fundingScopeLogo ?? organizationLogo

    organizationTab.tab_content.title = this.project.organization.title
    organizationTab.tab_content.text = this.project.organization.what_we_need.description
    organizationTab.tab_content.photos = this.project.organization.photos
    organizationTab.tab_content.links = this.project.organization.what_we_need.links
    organizationTab.tab_content.documents = this.project.organization.what_we_need.documents
    organizationTab.tab_content.contact_person = this.project.organization.contact_person
    organizationTab.tab_content.contact_person_logo = organizationLogo

    projectTab.color = this.orgaColor
    fundingScopeTab.color = this.orgaColor
    organizationTab.color = this.orgaColor

    this.tabsSet = true
  }

  getTabByKey (key: string): ITab {
    return this.tabs.find(tab => tab.key === key) as ITab
  }

  formatCurrency (val: number): string {
    const displayName = this.currencyDisplay.useCurrency ? this.currencyDisplay.currencySymbol : this.currencyDisplay.coinNamePlural
    return `${val} ${displayName}`
  }

  updateNavBalance (): void {
    document.querySelectorAll<HTMLElement>('.coin-balance').forEach(balance => {
      balance.innerText = this.currencyDisplay.useCurrency ? this.userProfile.balance.in_currency_display : this.formatCurrency(this.userProfile.balance.in_coins)
    })
  }

  updateUserBalance (): void {
    this.fetchUserBalance().then(() => {
      this.updateNavBalance()
    })
  }

  async updateNavbar (): Promise<void> {
    await axios.get(API_URLS.V3.TOPBAR_DATA).then(res => {
      document.querySelector<HTMLElement>('.adjust-navbar').innerHTML = res.data.template
    })
    this.updateNavBalance()
    this.loadProject()
  }

  onDonation (result): void {
    this.donationResult = result
    this.loadProject()
    this.updateUserBalance()
    this.donationSuccessModalOpen = !this.donationSuccessModalOpen
  }

  onSuccessModalClose (): void {
    this.donationResult = null
    this.donationSuccessModalOpen = !this.donationSuccessModalOpen
  }

  async loadProject (): Promise<void> {
    this.$wait.start('load project')
    let previewToken = this.$route.query?.preview_token
    if (Array.isArray(previewToken)) {
      previewToken = previewToken[0]
    }

    let url = ''
    if (this.isOrganization) {
      url = API_URLS.EXPLORE.ORGANIZATIONS.RETRIEVE(this.$route.params.orgSlug)
      if (previewToken) {
        url = API_URLS.EXPLORE.ORGANIZATIONS.PREVIEW(this.$route.params.orgSlug, previewToken)
      }
    } else {
      url = API_URLS.EXPLORE.PROJECTS.RETRIEVE(this.$route.params.projectSlug)
      if (previewToken) {
        url = API_URLS.EXPLORE.PROJECTS.PREVIEW(this.$route.params.projectSlug, previewToken)
      }
    }

    if (ExploreModule.projectDetailSettings) {
      await axios.get(url).then(res => {
        this.project = res.data
      })
    } else {
      await Promise.all([
        ExploreModule.fetchProjectDetailSettings(),
        ExploreModule.fetchCofundings(),
        axios.get(url).then(res => {
          this.project = res.data
        })
      ])
    }

    this.$wait.end('load project')
  }

  setExtraDataForPrivateDonation (): void {
    const urlParams = new URLSearchParams(window.location.search)
    const showcaseParam = urlParams.get('showcase')
    if (showcaseParam) {
      localStorage.setItem('donation_extra_data', showcaseParam)
    } else {
      localStorage.setItem('donation_extra_data', "")
    }
  }

  async created (): Promise<void> {
    this.fetchUserProfile()
    await this.loadProject()
    this.setExtraDataForPrivateDonation()

    document.title = `${this.project.title} - ${this.projectDetailSettings?.siteName}`
  }
}
