
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FormTitle from '../../FormTitle.vue'
import SingleSelectField from '../../commons/fields/SingleSelectField.vue'
import TextField from '../../commons/fields/TextField.vue'
import FormContainer from '../../commons/forms/FormContainer.vue'
import CharField from '../../commons/fields/CharField.vue'
import AddressForm from './AddressForm.vue'
import SingeLocationMap from './SingleLocationMap.vue'
import axios from 'axios'
import ConfirmAddressModal from './ConfirmAddressModal.vue'
import { IPSAddressForm, IPSLocationForm, IPSLocationFormErrors } from '@/types/processingSteps'

@Component({
  components: {
    SingleSelectField,
    TextField,
    FormTitle,
    FormContainer,
    CharField,
    AddressForm,
    SingeLocationMap,
    ConfirmAddressModal
  }
})
export default class LocationForm extends Vue {
  @Prop() locationForm!: IPSLocationForm
  @Prop({ default: false }) requiredFields!: string[]
  @Prop() locationFormErrors!: IPSLocationFormErrors

  confirmAddressModalOpen = false

  formattedAddress = {
    street: '',
    postal_code: '',
    city: '',
    country: '',
    region: '',
    additional_information: "",
    longitude: 0,
    latitude: 0
  }

  @Watch('locationForm', { deep: true })
  onLocationFormChange () {
    this.formattedAddress = this.locationForm
    this.onAddressFormBlur()
  }

  mounted () {
    this.formattedAddress = this.locationForm
  }

  onAddressFormBlur () {
    if (this.locationForm.street && this.locationForm.postal_code && this.locationForm.city && this.locationForm.country) {
      this.validateOrganizationAddress()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async fetchLocationData (): Promise<any> {
    const locationUrl = "/api/v3/actions/codeaddress/"
    const address = { address: `${this.locationForm.street} ${this.locationForm.postal_code} ${this.locationForm.city}, ${this.locationForm.country}` }
    const res = await axios.post(locationUrl, address).then((response) => {
      return response
    }).catch(() => {
      return null
    })
    return res
  }

  async validateOrganizationAddress (): Promise<boolean> {
    if (this.locationForm.street && this.locationForm.postal_code && this.locationForm.city && this.locationForm.country) {
      const geoLocResponse = await this.fetchLocationData()
      if (geoLocResponse) {
        if (geoLocResponse.data && geoLocResponse.data && geoLocResponse.data.results.length > 0 && geoLocResponse.data.results[0].partial_match) {
          this.confirmAddressModalOpen = true
          const address = geoLocResponse.data.results[0].formatted_address
          const cityAndPostalCode = address.split(',')[1]
          const street = address.split(',')[0] || this.locationForm.street
          const postalCode = cityAndPostalCode ? cityAndPostalCode.split(' ')[1] : this.locationForm.postal_code
          const city = cityAndPostalCode ? cityAndPostalCode.split(' ')[2] : this.locationForm.city
          const longitude = geoLocResponse.data.results[0].geometry.location.lng
          const latitude = geoLocResponse.data.results[0].geometry.location.lat
          this.formattedAddress = {
            street,
            postal_code: postalCode,
            city,
            country: this.locationForm.country,
            region: this.locationForm.region,
            additional_information: this.locationForm.additional_information,
            longitude,
            latitude
          }
          return false
        } else if (geoLocResponse.status === 200) {
          if (geoLocResponse.data && geoLocResponse.data && geoLocResponse.data.results.length > 0) {
            const geoLoc = geoLocResponse.data.results[0]?.geometry?.location ?? { lat: this.locationForm.latitude, lng: this.locationForm.longitude }
            this.formattedAddress = {
              street: this.locationForm.street,
              postal_code: this.locationForm.postal_code,
              city: this.locationForm.city,
              country: this.locationForm.country,
              region: this.locationForm.region,
              additional_information: this.locationForm.additional_information,
              longitude: geoLoc.lng,
              latitude: geoLoc.lat
            }
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }

  onKeepAddress (): void {
    this.confirmAddressModalOpen = false
  }

  onAddressChange (formattedAddress: IPSAddressForm): void {
    this.$emit('update:locationForm', formattedAddress)
    this.confirmAddressModalOpen = false
  }
}
