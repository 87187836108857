
import { Component, Vue } from 'vue-property-decorator'
import { IPSWhatWeNeedForm, IPSWhatWeNeedFormErrors } from '@/types/processingSteps'

@Component
export default class WhatWeNeedStepMixin extends Vue {
  whatWeNeedForm: IPSWhatWeNeedForm = {
    slug: '',
    title: '',
    description: '',
    document_album_slug: '',
    links: [],
    documents: [],
    mission_statement: '',
    founding_year: null,
    annual_budget: null
  }

  whatWeNeedFormErrors: IPSWhatWeNeedFormErrors = {
    title: '',
    description: ''
  }
}
