
import { Component, Mixins } from 'vue-property-decorator'
import ProcessingStepMixin from '../../ProcessingStepMixin.vue'
import { IPSEditProject, IProcessingStep } from '@/types/processingSteps'
import ProjectBaseInformationMixin from './ProjectBaseInformationMixin.vue'
import BaseInformationValidationMixin from './BaseInformationValidationMixin.vue'
import SubCategoryForm from '../../commons/forms/SubCategoryForm.vue'

@Component({
  components: {
    SubCategoryForm
  }
})
export default class ProjectStepMixin extends Mixins(ProcessingStepMixin, ProjectBaseInformationMixin, BaseInformationValidationMixin) {
  steps: IProcessingStep[] = []

  // todo add type for IEditProject with the required fields to create/update projects
  project: IPSEditProject | null = null

  additionalStepsAfterFirstStep = []

  firstStep: IProcessingStep = {
    key: "basic",
    title: this.$gettext("Basic information"),
    completed: false,
    required: true,
    active: true,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  secondStep: IProcessingStep = {
    key: "what_we_need",
    title: this.$gettext("What we need"),
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  thirdStep: IProcessingStep = {
    key: "contact_persons",
    title: "Kontaktpersonen",
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  fourthStep: IProcessingStep = {
    key: "assignment",
    title: this.$gettext('Categories and tags'),
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  fifthStep: IProcessingStep = {
    key: "individual_bank_account",
    title: "Finanzierungskonto",
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  sixtStep: IProcessingStep = {
    key: "funding_code",
    title: this.$gettext('Application'),
    completed: true,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  seventhStep: IProcessingStep = {
    key: "result_report",
    title: "Ergebnisbericht",
    completed: false,
    required: false,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  eightStep: IProcessingStep = {
    key: "completion",
    title: "Abschluss",
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: this.isSiteAdmin,
      saveAndNext: false,
      finish: {
        show: false
      }
    }
  }

  async assignmentIsValid () {
    if (!this.project) {
      return false
    }
    const hasFundingScope = this.project.funding_scope_slug !== ""
    const subCategoryForm = new SubCategoryForm({
      propsData: {
        projectCategories: this.project.project_categories,
        instance: { type: 'project', slug: this.project.slug }
      }
    })
    subCategoryForm.$mount()
    await subCategoryForm.setCategoriesAndShownCategories()
    const tagsValid = await subCategoryForm.stepIsValid()
    await this.$nextTick()
    const isValid = tagsValid && hasFundingScope
    subCategoryForm.$destroy()
    return isValid
  }

  setSteps (): void {
    this.steps = [
      this.firstStep,
      ...this.additionalStepsAfterFirstStep,
      this.secondStep,
      this.thirdStep,
      this.fourthStep,
      ...(this.isSiteAdmin ? [this.fifthStep] : []),
      this.sixtStep,
      this.seventhStep,
      this.eightStep
    ]
  }

  get whatWeNeedIsValid () {
    const requiredFields = ["description"]
    return requiredFields.every(field => this.project && this.project.what_we_need && this.project.what_we_need[field])
  }

  get photoStepIsValid () {
    const value = this.project?.photos.length > 0
    return value
  }

  get contactPersonIsValid () {
    return this.project?.has_public_contact_person
  }
}
