import { AvailableFilters } from '@/types/hdsFilter'
import { API_URLS } from '@/utils/helpers'
import axios from 'axios'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class FilterOptionsMixin extends Vue {
  requiredFilterOptions: AvailableFilters[] = []
  organizationOptions: { value: string | number, text: string }[] = []
  fundingScopeOptions: { value: string | number, text: string }[] = []
  membershipOptions: { value: string | number, text: string }[] = []
  numberOfEmployeesOptions: { value: string | number, text: string }[] = []
  projectFundingCodesOptions: { value: string | number, text: string }[] = []
  organizationLegalFormOptions: { value: string | number, text: string }[] = []
  organizationStatutoryPurposeOptions: { value: string | number, text: string }[] = []
  organizationCityOptions: { value: string | number, text: string }[] = []
  organizationCountryOptions: { value: string | number, text: string }[] = []
  fundingScopeTargetGroupOptions: { value: string | number, text: string }[] = []
  fundingScopeApproachOptions: { value: string | number, text: string }[] = []
  fundingScopeSocietyGoalsOptions: { value: string | number, text: string }[] = []
  fundingScopeEducationGoalsOptions: { value: string | number, text: string }[] = []
  fundingScopeCrisisReliefOptions: { value: string | number, text: string }[] = []
  fundingScopeElderlyOptions: { value: string | number, text: string }[] = []
  fundingScopeSocialOptions: { value: string | number, text: string }[] = []
  fundingScopeSportOptions: { value: string | number, text: string }[] = []
  fundingScopeAnimalWelfareOptions: { value: string | number, text: string }[] = []
  fundingScopeEnvironmentOptions: { value: string | number, text: string }[] = []
  fundingScopeYouthWelfareOptions: { value: string | number, text: string }[] = []
  fundingScopeSdgOptions: { value: string | number, text: string }[] = []
  fundingScopeScienceOptions: { value: string | number, text: string }[] = []
  fundingScopeArtOptions: { value: string | number, text: string }[] = []
  projectSpecialEventsOptions: { value: string | number, text: string }[] = []

  async fetchFilterOptions (): Promise<void> {
    for (const opt of this.requiredFilterOptions) {
      const filterOptions = this.fetchFilterOptionsFor(opt)
      switch (opt) {
        case 'organization':
          this.organizationOptions = await filterOptions
          break
        case 'funding_scope':
          this.fundingScopeOptions = await filterOptions
          break
        case 'tags_mitgliedschaft':
          this.membershipOptions = await filterOptions
          break
        case 'number_of_employees':
          this.numberOfEmployeesOptions = await filterOptions
          break
        case 'funding_codes':
          this.projectFundingCodesOptions = await filterOptions
          break
        case 'organization_legal_form':
          this.organizationLegalFormOptions = await filterOptions
          break
        case 'organization_statutory_purpose':
          this.organizationStatutoryPurposeOptions = await filterOptions
          break
        case 'organization_city':
          this.organizationCityOptions = await filterOptions
          break
        case 'organization_country':
          this.organizationCountryOptions = await filterOptions
          break
        case 'funding_scope_target_group':
          this.fundingScopeTargetGroupOptions = await filterOptions
          break
        case 'funding_scope_approach':
          this.fundingScopeApproachOptions = await filterOptions
          break
        case 'funding_scope_society_goals':
          this.fundingScopeSocietyGoalsOptions = await filterOptions
          break
        case 'funding_scope_education_goals':
          this.fundingScopeEducationGoalsOptions = await filterOptions
          break
        case 'funding_scope_crisis_relief':
          this.fundingScopeCrisisReliefOptions = await filterOptions
          break
        case 'funding_scope_elderly':
          this.fundingScopeElderlyOptions = await filterOptions
          break
        case 'funding_scope_social':
          this.fundingScopeSocialOptions = await filterOptions
          break
        case 'funding_scope_sport':
          this.fundingScopeSportOptions = await filterOptions
          break
        case 'funding_scope_animal_welfare':
          this.fundingScopeAnimalWelfareOptions = await filterOptions
          break
        case 'funding_scope_environment':
          this.fundingScopeEnvironmentOptions = await filterOptions
          break
        case 'funding_scope_youth_welfare':
          this.fundingScopeYouthWelfareOptions = await filterOptions
          break
        case 'funding_scope_sdg':
          this.fundingScopeSdgOptions = await filterOptions
          break
        case 'funding_scope_science':
          this.fundingScopeScienceOptions = await filterOptions
          break
        case 'funding_scope_art':
          this.fundingScopeArtOptions = await filterOptions
          break
        case 'project_special_events':
          this.projectSpecialEventsOptions = await filterOptions
          break
      }
    }
  }

  async fetchFilterOptionsFor(filterOptionKey: AvailableFilters): Promise<{ value: string | number, text: string }[]> {
    const url = API_URLS.FILTER_CONTAINER.FILTER_OPTIONS(filterOptionKey)
    try {
      const response = await axios.get(url)
      return response.data
    } catch {
      this.$emit('show-admin-error')
      return []
    }
  }
}
