
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormContainer from "@/components/processing_steps/commons/forms/FormContainer.vue"
import CurrencyField from "@/components/processing_steps/commons/fields/CurrencyField.vue"
import { IPSFinanceForm, IPSFinanceFormErrors } from '@/types/processingSteps'

@Component({
  components: {
    CurrencyField,
    FormContainer
  }
})
export default class ProjectFinancingNeedsForm extends Vue {
  @Prop() financeForm!: IPSFinanceForm
  @Prop() financeFormErrors!: IPSFinanceFormErrors
}
