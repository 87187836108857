
import { IDetailedFinancingNeed } from '@/types/processingSteps'
import { Vue, Component, Prop } from 'vue-property-decorator'
import FormContainer from './FormContainer.vue'
import CurrencyField from '../fields/CurrencyField.vue'
import CharField from '../fields/CharField.vue'

@Component({
  components: {
    FormContainer,
    CharField,
    CurrencyField
  }
})
export default class DetailedFinancingNeeds extends Vue {
  @Prop() detailedFinancingNeedsForm!: IDetailedFinancingNeed

  get totalNeed () {
    return Object.values(this.detailedFinancingNeedsForm).reduce((acc, item) => {
      return (parseFloat(acc) + parseFloat(item.need_in_cents)).toFixed(2)
    }, 0)
  }

  getItemTitle (key: string) {
    if (key === "investments") {
      return this.$gettext("Investments")
    } else if (key === "operating_costs") {
      return this.$gettext("Operating costs")
    } else if (key === "staff_costs") {
      return this.$gettext("Personnel costs")
    } else if (key === "other_costs") {
      return this.$gettext("Other costs")
    }
  }

  getItemDescription (key: string) {
    if (key === "investments") {
      return this.$gettext("e.g. for machines, software and materials in connection with the project")
    } else if (key === "operating_costs") {
      return this.$gettext("e.g. for office and administrative expenses, travel expenses, external service costs, communication and marketing, rent in connection with the project")
    } else if (key === "staff_costs") {
      return this.$gettext("e.g. salaries, fees, volunteer allowances, training for project staff")
    } else if (key === "other_costs") {
      return this.$gettext("Please describe other costs in detail")
    }
  }
}
